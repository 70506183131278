.progress {
    outline: 4px solid #16c988 !important;
    max-width: 160px;
    margin: 0 auto;
    border-radius: 50px;
    background-color: #140842;
    position: relative;
}

.progress-bar-striped {
    background-image: linear-gradient(135deg, #9284b9 25%, transparent 25%, transparent 50%, #9284b9 50%, #9284b9 75%, transparent 75%, transparent);
}

.progress-bar {
    background-color: #624f9e;
}

.underline-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

.input-text-white {
    color: #ffffff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.slick-next,
.slick-prev {
    top: 38% !important;
}

.slick-next {
    width: auto !important;
    height: 55px !important;
    right: 0px !important;
}

.slick-prev {
    width: auto !important;
    height: 55px !important;
    left: 14px !important;
    transform: rotateZ(180deg) !important;
    transform-origin: 17px 9px !important;
    z-index: 100;
}

.slider-block {
    padding-bottom: 1px;
}

/* .avatarModal {
    margin: 0 auto !important;
    max-width: 482px;
}

.avatarModal .modal-content {
    top: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
} */

.nickNameInput {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    height: unset !important;
}

.focusOn {
    box-shadow: 0 0 6px 0 rgba(255, 255, 255, 1);
}

.custom-green-text {
    color: #11b773 !important;
    font-size: 12px;
    font-weight: 600;
    align-self: center;
    top: 14px !important;
}

.kycDocImage {
    text-align: center;
}

.kycDocImage img {
    max-height: 40px;
    width: auto;
}

.kycAadharDocImage img {
    width: 100%;
    height: 90px;
    object-fit: scale-down;
}

.kycDocImage .docNumber {
    font-size: 12px;
    font-weight: 500;
    color: #ffffff !important;
}

.circle-modal {
    min-width: 50px !important;
}

.tail-bg {
    background: #27137a;
    overflow: hidden;
    margin-bottom: 4px;
}

.history_list {
    overflow: hidden;
    max-height: auto;
    transition: all 0.3s;
}

.clicked {
    max-height: auto;
    transition: max-height 0.3s, opacity 0.3s 0.3s;
}

.tail-bg.completed {
    background: transparent;
    border: 2px solid #11b773;
}

.stickyBlock {
    position: sticky;
    top: 0;
    height: calc(100vh - 334px);
    overflow-y: auto;
}

/* width */
.stickyBlock::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.stickyBlock::-webkit-scrollbar-track {
    background: #140842;
}

/* Handle */
.stickyBlock::-webkit-scrollbar-thumb {
    background: #27137a;
}

/* Handle on hover */
.stickyBlock::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.setting-modal {
    width: 480px !important;
    margin: 0 auto !important;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    max-width: 100% !important;
    align-items: unset;
    background-color: #27137a;
}

.setting-modal::before {
    content: "";
    background-image: url(/public/assets/img/modal-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}

.setting-modal .modal-content {
    border-radius: 0;
    background: transparent;
}

.custom-link {
    margin-top: 50px;
}

.custom-link ul {
    padding: 0;
    margin: 0;
}

.custom-link ul li {
    color: #fff;
    list-style: none;
    text-indent: 45px;
    border-bottom: 1px solid #756e90;
    padding: 20px 0;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.custom-link ul li:last-child {
    border: none;
}

.custom-link ul li .custom-nav-link {}

.custom-link ul li .custom-nav-link .icon {}

.custom-link ul li .custom-nav-link .icon img {
    width: 25px;
}

.logout-btn {
    background: #7924ff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    display: block;
    padding: 14px 10px;
}

.stickyBlock {
    position: sticky;
    top: 0;
    height: calc(100vh - 334px);
    overflow-y: auto;
}

.custom-tabs .tab-content {
    height: calc(100vh - 44px);
}

.nonMovableBlock {
    position: sticky;
    top: 0;
    height: 210px;
    z-index: 100;
}

.custom-tabs .tabs-container {
    position: sticky;
    top: 220px;
    height: 45px;
    z-index: 100;
    background-color: #140842;
}

#myTabContent {
    height: calc(100vh - 255px);
    overflow-y: scroll;
    padding-bottom: 44px;
}

/* width */
*::-webkit-scrollbar {
    width: 0px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #140842;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #27137a;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#withdrawAccordion .card {
    background-color: transparent !important;
}

#withdrawAccordion .card-header {
    padding: 0 !important;
}

#withdrawAccordion .card-header button {
    width: 100%;
    padding: 0;
    text-align: left;
}

#withdrawAccordion .card-header button img {
    max-height: 14px;
    width: auto;
}

#withdrawAccordion .card-header label {
    width: 100%;
    cursor: pointer !important;
}

#withdrawAccordion .card-body {
    padding: 10px 0;
}

.processingWithdrawalImg {
    width: 20%;
    animation: rotate 1s linear infinite;
    transform: rotateZ(0deg);
}

@keyframes rotate {
    100% {
        transform: rotateZ(360deg);
    }
}

.customSelect {
    background: transparent !important;
}

.withdrawableListModal {
    /* padding: 20px; */
    max-width: 460px;
    height: 100vh;
    margin-top: 0 !important;
}

.withdrawableListModal .modal-content {
    background-color: #E2F2FF !important;
}

/* .withdrawableListModal .modal-content .modal-body {
    overflow: auto;
    height: 100vh;
} */

.withdrawableTransactionDetailModal {}

.withdrawableTransactionDetailModal .modal-content {}

.withdrawableTransactionDetailModal .modal-content .modal-body {
    height: unset !important;
    max-height: unset !important;
    padding: 0 !important;
    border-radius: 10px !important;
}

.avtaar-user,
.avtaar-user img {
    height: 100px;
    width: 100px;
    object-fit: cover !important;
}

.input-group-append {
    width: auto;
    height: auto;
}

.avatarNickNameInput {
    position: relative;
    border-radius: 10px;
    background: #27137a;
    padding: 12px;
}

.uploadFrontBackModal {
    z-index: 100000;
}

.versionBottom {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.avatarImg img {
    object-fit: contain;
    border: 1px solid #16c988;
    border-radius: 50%;
}

.termsAndConditionsModal {
    max-height: 90vh;
    overflow-y: auto;
}

.termsAndConditionsModal .modal-content {
    max-height: inherit;
    overflow-y: auto;
}

.kycDocumentBottomModal .modal-content {
    max-height: 300px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #391199;
    width: 38px !important;
    height: 38px !important;
    z-index: 100;
    position: relative;
    display: block;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loading {
    100% {
        transform: rotateZ(360deg);
    }
}

@media screen and (max-width: 465px) {
    .front-respnsv button {
        padding: 0.375rem 1rem !important;
        font-size: 13px !important;
    }
}

.staticPageText {
    color: #ffffff !important;
}

.incBy {
    display: table-cell;
    width: 1%;
}

.incBy-box {
    color: #ffcd50;
    border: 1px solid #ffcd50;
    border-radius: 40px;
    padding: 4px 20px;
    cursor: pointer;
    transition: 0.4s ease;
    margin: 0 4px;
    text-align: center;
}

.incBy-box:hover {
    background-color: #ffcd50;
    color: #ffffff;
}

.enlargeImageModal .modal-content {
    padding: 0 !important;
    background-color: transparent !important;
}

.enlargeImageModal .modal-body {
    padding: 0 !important;
    background-color: transparent !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.fullScreenModal .modal-content {
    border: none;
}

.fullScreenModal .full-modal-content {
    width: 100%;
    border-radius: 0;
}

.fullScreenModal .full-modal-content .btn-close {
    top: 10px !important;
}

.fullScreenModal .full-modal-content .btn-close {
    background: transparent !important;
}

.fullScreenModal .modal-content .btn-close {
    position: absolute;
    padding: 0 !important;
    top: 2px;
    right: 6px;
    background: #000;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}

.fullScreenModal .modal-content .btn-close .cross {
    font-size: 30px;
    color: #fff;
    font-weight: 200;
    line-height: 31px;
}

:focus-visible {
    outline: none;
}

.fullScreenModal .full-modal-content iframe {
    height: calc(100vh - 56px);
    margin-bottom: -5px;
}

.listModal .modal-body-header .btn-close {
    right: 18px;
}

.pancard-style {
    border: 1px solid #1d0a5687 !important;
}

.custom-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000082;
    z-index: 11;
}

.connection-modal {
    height: 100%;
    margin: 0 auto;
    /* background: #000; */
    backdrop-filter: blur(10px);
}

.connection-modal .modal-content {
    border-radius: 10px !important;
    text-align: center;
    padding: 100px;
}

.dots-loading::after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
}

.start-0 {
    left: 0 !important;
}

.top-0 {
    top: 0 !important;
}

.end-0 {
    right: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.iframeBox {
    margin: 0 auto;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    flex: 1 1;
}

.video-container {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.video-container video {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
}

.progress {
    height: 10px;
    border: 1px solid #90c5ff;
    outline: none !important;
    background: #0067ff;

}

.progress-bar {
    background: #391199 !important;
}

.Download-button {
    position: absolute;
    bottom: 10px;
    right: 12px;
}

.Download-button a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Download-button a .button-text {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: end;
    gap: 4px;
}

.Download-button a::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 0px 7px 18px 10px #000000cc;
    height: 0px;
    display: block;
    width: 100%;
}

.level-box {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
}

.level-box img {
    position: unset;
    object-fit: scale-down;
    padding: 2px;
}

.Badges-modal-content {
    border-radius: 0;
    background: radial-gradient(85.96% 50% at 50% 50%, #48A2FE 0%, #0E4FA3 100%);
}

.Badges-modal-content .modal-body-header {
    background: linear-gradient(180deg, #1C6DC6 0%, #033E7E 100%);
    box-shadow: 0px 0px 1px 2px #509CEF;
    border-radius: 0;
    height: 65px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.Badges-modal-content .modal-title {
    line-height: normal;
}

.Badges-modal-content .modal-body-header .btn-close {
    right: unset;
    top: unset;
}

.Badges-content {
    border-radius: 21px;
    border: 1px solid #FAC800;

    background: #0B3A7C;
}

.Badges-tile {
    border-radius: 21px;
    border: 1px solid #358FF0;
    background: #144FA1;
    display: flex;
}

.Badges-tile .Badges-box {
    width: 95px;
    height: 95px;
    position: relative;

}

.Badges-tile .Badges-box img {
    width: 95px;
    height: 95px;
    /* mix-blend-mode: soft-light; */
}

.Badges-tile .Badges-box .inner-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 10px;
}

.Badges-tile .Badges-box .inner-content img {
    width: 100%;
    height: auto;
}

.Badges-tile .text-yellow {
    color: #F8DC43 !important;
}